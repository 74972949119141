.session-adm .ant-picker-body {
    padding: 0 !important;
}

.session-adm .ant-picker-calendar td {
    border: 1px solid #e9e9e9;
    height: 96px;
}

.calendar__day {
    padding: 8px;
    height: 100%;
    margin: 0;
    cursor: default;
}

.calendar__date {
    height: 24px;
    width: 24px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar__tag {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.calendar__controls {
    margin-left: 16px;
    width: 200px;
}

.session__modal {
    width: 30%;
}

.edit-session__content {
    margin-bottom: 30px;
}

.edit-session__coll {
    box-sizing: border-box;
    padding-bottom: 16px;
    border-bottom: 1px solid #f1f1f1;
}

.edit-session__label {
    color: #7c7c7c;
    padding-left: 8px;
}
