.marginBottom1 {
    margin-bottom: 8px;
}

.marginBottom2 {
    margin-bottom: 16px;

}

.marginBottom4 {
    margin-bottom: 32px;

}