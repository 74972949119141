.rules-block__title {
    font-size: 20px;
}

.rules-block__sub_title {
    padding: 5px 10px;
    font-size: 14px;
    background: #fafafa;
    margin-bottom: 10px;
}

.rules-block__title,
.rules-block__sub_title {
    font-weight: 600;
    line-height: 20px;
}

.rules-block__options_describe {
    color: #7c7c7c;
    padding-right: 8px;
}

.rules-block__options_value > .ant-form-item {
    margin: 0;
}

.rules-block__options_divider {
    margin: 2px 0;
}

.rules-tabs__label_inactive {
    color: rgba(0, 0, 0, 0.25) !important;
    text-shadow: none;
}

.rules-collapse__vocab_panel .ant-collapse-content-box {
    padding: 0 !important;
}

.rules-content > :not(:last-child) {
    margin-bottom: 16px;
}

.rules-block__items > :not(:last-child) {
    margin-bottom: 5px;
}

.rules-block__vocabulary-add-item {
    padding: 10px 10px 0;
}

.dictionary-select {
    display: flex;
    align-items: flex-start;
}

.dictionary-select__input {
    flex-grow: 1;
    margin-right: 10px;
}

.activity-type {
    width: 240px;
}

.rules-collapse__vocab_panel .ant-table-thead th.ant-table-column-sort {
    background: #fafafa;
}
.rules-collapse__vocab_panel .ant-table-wrapper td.ant-table-column-sort {
    background: transparent;
}

.rules-filter__item {
    display: flex;
    flex-direction: column;
}
.rules-filter__item label {
    margin-bottom: 8px;
    color: #7c7c7c;
}
