.session-error-message {
    text-align: left;
    margin-top: -16px;
}

.session-progress-bar {
    margin-bottom: 0px;
}

.session-empty-progress {
    width: 350px;
}

.session-progress-tooltip {
    max-width: 400px;
}
