.user-history__title {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    cursor: pointer;
}

.user-history__title>* {
    margin-right: 1rem;
}

.user-roles__tag {
    border-color: transparent;
}

.user_history__cell {
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
}