.filter {
    padding: 8px;
}

.filter__wrapper {
    margin-bottom: 8px;
}

.filter__field {
    width: 180px;
}

.filter__buttons {
    display: flex;
    justify-content: space-between;
}

