@import url('./shared.css');
@import url('./alert.css');
@import url('./filterDropDown.css');
@import url('./rules.css');
@import url('./users.css');
@import url('./login.css');
@import url('dictionary.css');
@import url('./sessionAdministration.css');
@import url('./session.css');

.App {
    height: 100%;
}

/*глобальні стилі таблиць та форм*/
.ant-modal-root .ant-form-item {
    margin-bottom: 8px;
}

.App .ant-form-item {
    margin-bottom: 0;
}

.App .ant-form-item-label,
.ant-modal-root .ant-form-item-label {
    padding: 0 0 4px;
}

.App th,
.ant-drawer th {
    padding: 2px 4px !important;
}

.App .ant-table-row td,
.ant-drawer .ant-table-row td {
    padding: 2px !important;
}

.App .ant-table-thead th.ant-table-column-sort {
    background: #fafafa;
}
.App .ant-table-wrapper td.ant-table-column-sort {
    background: transparent;
}

.ant-drawer .ant-drawer-close {
    order: 1;
    margin-inline-end: 0;
    margin-inline-start: 8px;
}

.column-resizer {
    overflow: hidden;
    text-overflow: ellipsis;
}

.column-resizer__cursor {
    position: absolute;
    bottom: 0;
    right: 0;
    user-select: none;
    cursor: col-resize;
    width: 8px;
    height: 8px;
    border-right: 3px solid rgba(0, 0, 0, 0.1);
    border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}

.ant-tabs-ink-bar {
    background: rgba(5, 5, 5, 0.06) !important;
    width: 100% !important;
}

.ant-tabs-tab-btn {
    max-width: 15rem !important;
    word-wrap: normal !important;
}

.ant-tabs {
    max-height: 70vh !important;
}

.ant-tabs-tab {
    padding: 0 12px !important;
}

.ant-modal-header {
    width: 95%;
}

.table__checkbox {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.antd__tab {
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.textEnd {
    text-align: end;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
    color: #fff !important;
}

.wWide {
    width: 100%;
}

.history__cell {
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-collapse-header {
    padding: 4px 16px !important;
    align-items: center !important;
}

.ant-collapse-header .ant-row {
    align-items: center;
}

.alignItemsCenter {
    align-items: center;
}

.ant-tabs-nav-operations {
    position: absolute;
    visibility: hidden;
}

.active > .anticon-filter,
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active,
.active > .anticon-search {
    color: #900020 !important;
}

.ant-layout-footer {
    padding: 12px 0 !important;
}

.ant-card-body {
    padding: 12px !important;
}

.copyright:hover {
    cursor: pointer;
    user-select: none;
}

.tab_children {
    overflow-y: auto;
    max-height: 70vh;
}

.padding_0 {
    padding: 0;
}

.height_auto {
    height: auto;
}

.ant-skeleton-paragraph > li {
    height: 100% !important;
}
